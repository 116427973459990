import React, { useState } from "react";
import { Button, Grid, Box } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { Formik } from "formik";
import InputField from "../components/InputField";

import { ChangePasswordSchema } from "../utils/validation";
import Alert from "@material-ui/lab/Alert";
import FormColumn from "src/components/FormColumn";
import Error from "src/components/Error";

const ForgotPassword = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  async function handlePasswordChange(event, values) {
    event.preventDefault();
    const userObj = await Auth.currentAuthenticatedUser();
    console.log(userObj);
    try {
      await Auth.changePassword(
        userObj, // the Cognito User Object
        values.oldPassword, // the new password
        // OPTIONAL, the required attributes
        values.newPassword
      );

      setError(null);
      setSuccess(true);
    } catch (error) {
      console.log(error);

      setError(error.message);
    }
  }

  return (
    <Formik
      initialValues={{
        newPassword: "",
        oldPassword: "",
      }}
      validationSchema={ChangePasswordSchema}
    >
      {({ values }) => (
        <>
          <form onSubmit={(event) => handlePasswordChange(event, values)}>
            <FormColumn>
              <InputField
                type="password"
                name="oldPassword"
                label="Old password"
              />
              <InputField
                type="password"
                name="newPassword"
                label="New password"
              />
              <Grid item>
                {" "}
                <Button type="submit">Change password</Button>
              </Grid>
            </FormColumn>
          </form>

          {error && <Error error={error} />}
          {success && (
            <Grid item xs={6}>
              <Box mt={2}>
                <Alert variant="outlined" severity="success">
                  Your password has been changed
                </Alert>
              </Box>
            </Grid>
          )}
        </>
      )}
    </Formik>
  );
};
export default ForgotPassword;
